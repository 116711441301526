@import 'system/styles/funcs.scss';


.narrativeContainer{
    visibility: hidden;
    // background-color: var(--narrative-background);
}
.container{
    overflow-y: scroll;
    overflow-x: hidden;
    visibility: hidden;
    will-change: transform;
    touch-action: none;
    overscroll-behavior:none;   
}

.scrollPosition{
    position:absolute;
    display: none;
    top:0;
    width:10px;
    height: 100%;
    
}
:global(.ipad) .scrollPosition{
    scroll-snap-align: start;
    display: block;
 
}
.containerScroll{
    height: 100%;
    width: auto;
    position: relative;

    // display: flex;
    // flex-direction: column;
    // white-space: nowrap;
}
:global(.ipad) .containerScroll{
    scroll-snap-align: none;
}

.menuContainer{
    position: absolute;
    background-color:var(--primary-background);
    display: flex;
    left:0;
    top:0;

    // filter: drop-shadow(5px 0px 5px rgba(0,0,0,0.5));
}

.leftNav.menuContainer {
    width:460px;
    height: 100%;

    // filter: drop-shadow(5px 0px 5px rgba(0,0,0,0.5));
}

:global(.controllerApp) .leftNav.menuContainer {
    width:400px;
    top: 0;
    height: 100vh;
    position: fixed;
}

.onLanding.menuContainer {
    width:100%;
    height: 100%;

   
}

.menuContainer.moduleNavOpen {
    transition: all 1s ease;
}

.menuContainer.moduleNavOpen .menuBurger {
    opacity: 0;
    left: 0;
}


.menuContainer.moduleNavClosed {
    left: -100%;
    transition: all 1s ease;
}


.categoryLandingOn {
    z-index: 4;
    left: -100%;
}

.categoryLandingOn .buttonContainer {
    position: absolute;
    bottom: 2.7%;
    left: calc(100vw + var(--sideIndent) - 40px);
    transition: opacity 0.3s ease 1s;
    opacity: 1;
    // left: 0;
    // margin-left: 0 !important;
}


.categoryLandingOn .menuBurger, .categoryLandingOn .toolBtn, .categoryLandingOn .leftNavBurger {
    display: none;
}


// :global(.serverApp) .menuColRight {
//     opacity: 0;
// }

:global(.serverApp) .menuColRight, :global(.serverApp) .sideDragger {
    display: none !important;
}

:global(.receiver) .menuColRight, :global(.receiver) .sideDragger, :global(.receiver) .buttonContainer  {
    display: none !important;
}

$menuModuleContainerWidth : scaleValue(60px,80px);
.menuContainer[overlap]{
    // filter: drop-shadow(10px 3px 20px rgba(0,0,0,0.5));
}

.menuColRight{
    position: relative;
     -webkit-overflow-scrolling: touch;
    transform:translate3d(0,0,0);
    backface-visibility: hidden;
    background-color: var(--primary-background);
    // background: var(--primary-background-gradient);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, var(--primary-background) 100%);
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    // min-width: scaleValue(328px,461px);
    // padding-right: var(--dragger-width);
    box-shadow: 20px rgba(0,0,0,0.5);

}

.onLanding .menuColRight {
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, var(--primary-background) 100%);
}

.onLanding .menuInnerColumn {
    width: 61%;
    height: 80vh;
    margin-left: 2%;
    padding-top: 20vh;
    max-width: 1920px;
}

.leftNav .menuBg {
    // transition: opacity 0.3s ease 0.5s;
    // opacity: 0;
    display: none;
}

.leftNav .menuInnerColumn {
    width: calc(100% - 40px);
    height: calc(100vh - 100px);
    margin-left: 20px;
    padding-top: 50px;
  
}

.leftNav .menuInnerColumn .logo {
    max-width: 320px;
    width:auto;
    height: 100%;
    max-height: 50px;
    object-fit: contain;
    // border: 1px solid white;
}

:global(.controllerApp) .menuInnerColumn :global(.logo) {
    max-width: 260px;
    max-height: 45px;
}

.leftNav .homeBtn, .switchMenuBtn {
    width: calc(calc(38px + var(--lerpAspect) * 4px) * var(--lerpScale));
    height: calc(calc(38px + var(--lerpAspect) * 4px) * var(--lerpScale));
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: calc(calc(19px + var(--lerpAspect) * 2px) * var(--lerpScale));
    border: 1px solid var(--svg-stroke);
    // border: 1px solid white;
    // position: relative;
    z-index: 4;

}

.homeBtn svg {
    width:100%;
    height: 100%;
}

.switchMenuBtn svg {
    width:70%;
    height: 70%;
}


.leftNav .homeBtn polygon {
  stroke: var(--svg-stroke)  
}

.switchMenuBtn {
    justify-content: center;
    border: none;
    // position: relative;
    // margin-left:
}

.switchMenuBtn path {
    fill: var(--primary)  
}

// .onLanding .menuColPos h4 {
//     display: none;
// }

// .leftNav .menuColPos h4 {
//     display: block;
// }

.switchMenu h4 {
    font-size: scaleValue(20px, 26px);

}

:global(.controllerApp) .switchMenu h4 {
    font-size: 20px;
}


.leftNav .menuColPos {
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s ease;
    height: 0;
    overflow: hidden;

}

.leftNav .menuColPos.menuColOn, :global(.active) .menuColPos  {
    opacity: 1;
    height: auto;

}

:global(.controllerApp) .leftNav .menuColPos {
    height: 100%;
    // background-color: var(--primary-background);
    // width: 330px;
}


.menuColPosContainer {
    height: 100%;
}


.switchMenu {
    display: flex;
    justify-content: flex-start;
    gap: 18px;
    height: 32px;
    align-items: center;
    position: relative;
    // left: -32px;

    cursor: pointer;
}

.switchMenu path {
    stroke: white;
}

.menuLogo{
    object-fit: contain;
    width: 80%;
    max-width: 180px;
    // max-height: 10%;
    // height: auto;
    // width:calc(100% - 134px);
    margin: 40px 0 40px $menuModuleContainerWidth;
    object-position: center;
    display: block;
    
}

:global(.serverApp) .menuLogo{
    margin-left:32px;
}

.menuTitle{
    color:var(--primary);
    font-size: scaleValue(22px,30px);
    font-weight: 700;
    font-family: 'GothamBold';
}

.onLanding .menuTitle {
    margin: 10% 0 40px 0;
}

.onLanding .menuGrid .menuTitle {
    margin: 10vh 0 40px 0;
}

.leftNav .menuTitle {
    margin: 40px 0;
}

.menuRightBg{
    position: absolute;
    pointer-events: none;
    right:0;
    width: auto;
    min-width: 100%;
    z-index:-1;
    max-width: 100%;
    height:100%;
    object-fit: cover;
}


.menuBg{
    width:100%;
    height:100%;
    // border-radius: 0  0 15vh 0;
    overflow: hidden;
    object-fit: cover;
    object-position: left center;
    background-color: var(--secondary-background);
    object-fit: cover;
    object-position: center;
    position: absolute;
    
}

// :global(.controllerApp) .menuBg {
//     border-radius: 0;
// }

.menuRightColContainer{
    position: relative;
    top: 30px;
    align-items: flex-start;
    display: flex;
    justify-items: flex-start;
    width: 100%;

    // height: calc(100% - 200px);
    max-height:100%;
    flex-shrink: 1;
    overflow: hidden;
    // transform:scale(var(--scaleiPad));
    transform-origin: top left;
}

:global(.serverApp) .menuRightColContainer{
    margin-left: 10px;
}


:global(.receiver) .menuRightColContainer{
    margin-left: 10px;
}

:global(.receiver) .leftNav {
    display: none;
}

.leftNav .menuRightColContainer{
    height: calc(100% - 100px);
}

.menuLinksContainer{
    padding: 0 0 0 0;   
    height: 100%;
    overflow-y: scroll;
    /* width: 210px; */
    transition: opacity 0.3s ease;
    position: relative;
    top: 0px;
    opacity: 1;
    width: 435px;
    max-height: calc(100vh - 200px);

}

.menuLinksContainer  h4 {
    color: var(--primary)
}

:global(.controllerApp) .leftNav .menuLinksContainer{ 

    height: 100%;
    // width: 360px;
    padding: 0;
    // height: 100vh;
    // width: 330px;
    top: 180px;
    max-height: calc(100vh - 180px);
    max-width: 400px;
}

.onLanding .menuLinksContainer, .menuColOn .menuLinksContainer {
    opacity: 0;
    transition: opacity 0.3s ease;
}



.leftNav .menuColOn .menuLinksContainer {
    opacity: 1;
    transition: opacity 0.3s ease;
   
}

.menuModuleContainer{   
    width: 100%;
    display: grid;
    // flex-direction: column;
    align-items: center;    
    justify-content: flex-start;
    overflow-y: scroll;

}

.menuGrid .menuModuleContainer{
    display: grid;
    grid-template-columns: repeat(4, 120px);
    // grid-template-rows: repeat(2, 1fr);
    gap: 48px;
}

:global(.controllerApp) .menuGrid .menuModuleContainer {
    grid-template-columns: repeat(4, 100px);

}

:global(.controllerApp) .menuColPos .menuModuleContainer {
    align-items: flex-start;
    grid-template-rows: auto;

}

.menuRow .menuModuleContainer{
    display: grid;
    grid-template-columns: repeat(4, 120px);
    // grid-template-rows: repeat(2, 1fr);
    gap: 48px;
}


.leftNav .menuModuleContainer {
    grid-template-columns: repeat(2, 120px);
    padding: 0 40px;
    width: calc(100% - 40px);
    gap: 54px;
    row-gap: 27px;
    padding-top: 12px;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    align-items: flex-start;
}

.leftNav .menuModuleContainer .moduleLink:last-of-type {
    padding-bottom: 100px;
}

:global(.controllerApp) .leftNav .menuModuleContainer {
    grid-template-columns: repeat(2, 92px);
    // padding: 0 25px;
    gap: 30px;
    max-height: calc(100% - 200px);
    width: 100%;
    padding: 0 20px;
 
}

:global(.controllerApp) .leftNav .menuGrid .menuModuleContainer {
    grid-template-columns: repeat(3, 1fr);
    max-width: 345px;
    padding: 0;
    grid-template-rows: repeat(4, 1fr);
    align-items: flex-start;

}

@media only screen and (max-aspect-ratio:144/100) {
    /* .menuModuleContainer{
        min-width:76px;
    } */
}


.menuLink{
    font-size: scaleValue(18px,28px);
    // font-weight: scaleValue(400,200);
    font-weight: 400;
    margin:calc(24px * var(--lerpScale)) 0;
    cursor: pointer;
    transition: color 0.25s ease-in-out;
    position: relative;
    left: 70px;
    width: 320px;
    display: block;
    
}

:global(.galleryMenu).menuLink {
    padding-bottom: 0 !important;
}

.menuLink span[type=page] {
    display: flex;
    flex-direction: column;
    width: 100%;

    justify-content: flex-start;
}

:global(.controllerApp) .menuLink {
    width: 260px;
    left: 0;
    margin: 0;

}

:global(.controllerApp) .galleryLinksContainer .menuLink span[type=page] {
    pointer-events: none;

}

.linkText {
    text-align: left;
    width: 100%;
    // pointer-events: none;
}


.menuLink img {
    float: left;
    width: 90%;
    margin-right: 10%;
    height: auto;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid var(--primary);
}

:global(.linkOn).menuLink img {
    border: 1px solid var(--secondary);
}

.narrativeContainer .menuLink img {
    display: none;
}

.narrativeContainer .menuLink.menuSubLink img {
    display: inline;
}

:global(.controllerApp) .menuLink img {

    // left: 0;
    // width: auto;
    // margin-top: 0;
}

:global(.controllerApp) .menuLink:nth-child(1) {
    margin: 0;
} 

.groupLink {
    color: var(--primary)
}

.controlsMenuLink .groupLink {
    // color: var(--p)
    // color: #888;
    color: #0F3557;
}

:global(.linkOn), :global(.linkOn).menuSubLink, .groupLink:hover, span[type="page"]:global(.linkOn) {
    color: var(--secondary);
}



.menuLink:last-child {
    padding-bottom: 150px;
}

.galleryLinksContainer .menuLink:last-child {
    padding-bottom: 0;
}


.menuLink :global(span){
    display: flex;
    align-items: center;
}

.menuSubContainer{
    height:0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // margin: 20px 0;
}

.menuLink.menuSubLink{
    padding-left: 0;
    opacity: 0.75;
    left: 0;
    margin: 0;
}

.menuSubLink span, .menuSubLink img {
    pointer-events: none;
}

.menuSubLink:last-child {
    // margin-bottom: 0;

}
.menuLink.menuSubLink:last-child {
    padding-bottom: 0;
}

:global(.controllerApp) .menuSubLink{
    padding-left: 0;
    opacity: 0.75;
}

:global(.controllerApp) .menuSubLink .linkText {
    display: none;
}

.menuLink:hover {
    color: var(--hover-color) !important;
}



.menuLink:hover .menuLink {
    color: var(--primary) !important;
}


.menuLink .menuLink:hover {
    color: var(--hover-color) !important;
}

.menuLink:hover :global(.themeable) {
    // stroke: var(--svg-stroke-hover) !important;
}

span[type="group"] svg{
    // justify-content: space-between;
    // display: none;
}

.linkCaret {
    transform: rotate(90deg);
    width: 32px;
    height: 32px;
    top: 3px;
    left: -6px;
    position: relative;
}

.linkCaret :global(.themeSVGStroke) :global(.themeable) {
    stroke: var(--primary)
}

.menuLink[open] :global(.rotate180){
    transform: rotate(270deg); 
  }
  .menuLink :global(.rotate180){
    transform: rotate(180deg);
    transition: transform 0.35s ease-in-out;
  }
.menuLink:nth-child(1){
    margin-top: calc(12px * var(--lerpScale));
}

.galleryLinksContainer .menuLink:nth-child(1){
    margin-top: 0;
}

.homeBtnCol {
    display: flex;
    gap: 26px;
}


.buttonContainerBkg {
    position: absolute;
    left: calc(var(--sideIndent) * -1);
    bottom:calc(var(--sideIndent) * -1);

    height: 120px;
    width: 460px;
    
    // background: linear-gradient(0deg, rgba(#000000,1) 0%, rgba(#000000,1) 30%, rgba(#000000,0) 100%);
}

.buttonContainer{
    position: absolute;
    width: 380px;
    bottom: 2.7%;
    margin-left:var(--sideIndent);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    gap: 18px;
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));

}

.onLanding .buttonContainer {
    width: auto;
}

.buttonContainerMenu {
    display: flex;
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    width: auto;
    gap: 18px;
}

:global(.controllerApp) .buttonContainer {
    margin-left: var(--sideIndent);
    width: 300px;
}

:global(.narrative-holder) .buttonContainer {
    margin-left: var(--sideIndent);
}

.buttonContainer :global(.round-btn) {
    margin-bottom: 0;
    // margin-right:var(--sideIndent);
}


:global(.controllerApp) .buttonContainer{
    z-index: 3;
}

.btn:last-child(){
    margin-bottom: 0;
}
.btnSpacer{
    width:100%;
    height:100%;
    flex:1;
    pointer-events: none;
}

.moduleLink, .moduleLink span {
    width: 100%;
}

.moduleLink:nth-of-type(1){
    padding-top: 0;
}

.moduleLinkIcon {

}

.moduleLinkIcon span {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 80px;
  
}

.moduleLinkIcon p {
    text-align: center;
    // width: 100%;
}

.moduleLinkIcon svg{
    // width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;   
    // padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;   
    cursor: pointer;
    --svg-stroke:var(--primary-background);
    --svg-stroke-hover:var(--primary-accent);
    background: white;
    aspect-ratio: 1/1;
    width: 100%;
    border-radius: 20px;
    filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.1));
}

.moduleLink :global(.themeable){
    transition: stroke 0.25 ease-in-out;
}
.moduleLink:hover :global(.themeable),
.moduleLink[highlight="true"] :global(.themeable)
{
    stroke:var(--svg-stroke-hover) !important;

}
.moduleLink:nth-child(1){
    margin-top:0
}
.moduleLink :global(img),
.moduleLink svg{
    height:auto;
    // width:50%;
    // max-width:32px;
}

.moduleLink div {
    display: flex;
    justify-content: center;
}

:global(.controllerApp) .leftNav .moduleLink svg {
    max-width: 92px;
}

.menuContainer[overlap] .sideDragger{
    // filter: drop-shadow(5px 0px 5px rgba(0,0,0,1));
}

.sideDragger{
    --svg-stroke: var(--secondary);
    --svg-stroke-hover: var(--primary-accent);
    position: absolute;
    top:0;
    width: var(--dragger-width);
    bottom:0;
    display: flex;
    background-color: rgba(255,255,255,0.25);
    align-items: center;
    cursor: pointer;
    right:0px;
    // border-right: 1px solid rgba(255,255,255,0.8);
    
    // filter: drop-shadow(5px 0px 5px rgba(0,0,0,0));
    transition: background-color 0.15s ease-in-out, filter 2s ease-in-out;
    justify-content: center;
}
.sideDragger svg{
    width:100%;
    height: auto;
    transform: rotate(90deg);
    position: absolute;
}
.sideDragger:hover,
.sideDragger:active{
    background-color: rgba(255,255,255,0.5);    
}
.sideDragger line{
    stroke:var(--svg-stroke)
}
.sideDragger:hover :global(line),
.sideDragger:active :global(line){
    stroke: var(--svg-stroke-hover) !important; 
}
.sideDragger:active{
    background-color: rgba(255,255,255,0.5);
}

.topBarHolder{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:4px;
    display: flex;
    justify-content: space-evenly;
    background-color:rgba(255,255,255,1);
    visibility: hidden;
    opacity: 0;
}
.topBarHolder[loaded="true"]{
    visibility:visible;
}

.bar{
   height:calc(100% - 1px); 
   width:100%; 
//    cursor: pointer;
   gap:1px;
   margin:0 1px 1px 1px;
   background-color:black;
   transition: background-color 0.25s ease-in-out;

}

.barOn {

    background-color:var(--active-color)!important;
}

// .bar:hover{
//     opacity:1;
//     background-color:var(--hover-color) !important;
// }

// .bar[active="true"]{
//     background-color:var(--active-color)!important;
// }

.btnBack{
    position: absolute;
    left:34px;
    top:38px;
}

.btnBookmark {
    display: none;
}

.btnBookmark:global(.selected) :global(.round-btn) path  {
    fill:var(--svg-stroke)!important;
}

.animBandContainer{
    position: absolute;
    // right:calc(100% - calc(173px * var(--scaleiPad)));
    left:70%;
    height:100%;
    width:100%;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    z-index: 1;
    border-radius: 0;
    // border-bottom-right-radius: calc(-173px * var(--scaleiPad)); 
    overflow: hidden;
    opacity: 0;
}
.animBand{
    height: 100%;
    position: relative;
}
.animBandCorner{
    height: calc(173px * var(--scaleiPad));
    width:calc(173px * var(--scaleiPad));
    position: absolute;
    right:100%;
    bottom:0;
}


.loaderBG{
    opacity:1;
    background-color: rgba(20,20,20,1);
}

.loaderBG :global(svg) {
    opacity:0;
    transform: scale(0.75);
}

.pageLoader.loaderBG  {
    background-color: transparent;
}

.pageLoader.loaderBG :global(svg) {
    opacity:1;
    transform: scale(1);
}


:global(.serverApp) .pageLoader {
    display: none;
}

.controlsContainer{
    position: absolute;
    overflow: hidden;
    opacity: 0;
    bottom:26px;
    left: calc(50% - 40.75px);
    // left: 50%;
    // width: 120px;
    display: flex;
    flex-direction: column;
    // background-color: #EFEFF0;
    // background-color: var(--primary-background);
    border-radius: 20px;
    border-color:rgba(53,56,57,0.34);
   
    filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.1));

}

:global(.receiver) .controlsContainer {
    display: none;
}

.controllsBtn{
    cursor: pointer;
}
.controlsTop{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    height:32px;
    cursor: pointer;
}

.controlsTop svg{
    width: 32px;
    height: 32px;   
}
.controlsBtm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    border-bottom: 1px solid #fff;
}
.controlsTop:hover :global(svg) :global(rect){
    fill:black;
    // transform: scale(1.2);
    opacity: 0.75;
    transform-origin: center center;
}
@media (hover: hover) {
    .controlsBtm :global(svg:hover) :global(path),
    .controlsBtm :global(svg:hover) :global(line){
        // transform: scale(1.15);
        // transform-origin: center center;
        stroke: var(--svg-stroke-hover);
    }
}
.controlsBtm :global(svg:active) :global(path),
.controlsBtm :global(svg:active) :global(line){
    // transform: scale(1.15);
    // transform-origin: center center;
    stroke: var(--svg-stroke-hover);
}
.controlsBtm svg{
    width:auto;
    height: 67%;
    cursor: pointer;
    margin:4px;
}
.controlsSeparator{
    background-color: #707070;
    opacity: 0.5;
    width: 2px;
    height:50%;
}

.controlsPill {
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    // background-color: var(--round-btn-background-color);
    background-color: rgba(255,255,255,1);

    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    // box-shadow: 0px 3px 1px rgba(0,0,0,0.1);
    transition: all 0.3s ease;

}

:global(.serverApp) .controlsPill {
    display: none;
}

.off {
    opacity: 0.3;
}

.controlsPill svg:active {
    scale: 1.15
}

.controlsPill svg {
    padding: 0 4px;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
}

.controlsPillLine {
    border-right: 0.5px solid #e6e6e6;
    width: 1px;
    height: 100%;
}

// .controlsPill svg:first-of-type {
    
// }

.controlsBtmDrawer{
   overflow: hidden;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   max-width: 173px;
   
}
.controlsMenuContainer{
    max-height: 295px;
    overflow-y: scroll;
    overflow-x:none;
}

.controlsMenuLink{
    font-size: 16px;
    color:#0F3557;
    line-height: 30px;
    cursor: pointer;
    padding:0 11px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  
}
.controlsMenuContainer>.controlsMenuLink:first-of-type{

   padding-top: 11px;
}

.controlsMenuContainer>.controlsMenuLink:last-of-type{
   padding-bottom: 11px;
}

.controlsMenuLink:active{
    color:white;
}
.controlsModuleLinks{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 11px;
    width: calc(100% - 22px);
    max-width: 173px;
    border-top: 1px solid #fff;
    padding: 11px;
    align-items: center;
    justify-items: center;
}

.controlsModuleLink{
    cursor: pointer;
    --svg-stroke:#3C4347;

}

.controlsModuleLink svg{
    height:auto;
    width:100%;
    max-width:32px;
}

@media (hover: hover) {
    .controlsMenuLink:hover{
        opacity:0.75
    }
    .controlsModuleLink:hover{
        opacity: 0.75;
    }
}

.controlsMenuGrabber{
    background-color: #C5CCD3;
    width: 100%;
    height:32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 32px;
    max-height: 32px;

}

.controlsMenuGrabber :global(svg){ transform: rotate(0deg); height: 23px;  pointer-events: none;}
// .controlsMenuGrabber :global(line){ stroke: white; }

.drawControlsContainer{
    // min-width:171px;
    background: #fff;
    z-index: 2;
}
.drawControlsColorRow{
    display: flex;
    width: calc(100% - 24px);
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 12px 12px 0 12px;
}

.drawControlsColorRowBtm {
    padding-bottom: 10px;

}

.drawControlsColor:first-child{
    // margin-left: 16px;
}
.drawControlsColor{
    width:15px;
    height:15px;
    // margin: 16px 8px;
    cursor: pointer;
    border-radius: 24px;
    // border: 1px solid transparent;
    border:1px solid #e6e6e6
}
.drawControlsClearBtn{
    font-weight: 700;
    font-family: 'GothamBold';
    font-size:16px;
    color:#0F3557;
    --hover-color: var(--primary-accent);
    cursor: pointer;
    // flex: 2;
    width: 100%;
    text-align: right;
}
.drawControlsClearBtn:hover{
    color:var(--hover-color);
}
.drawControlsBrushBtn{
    // margin-left: 8px;
    cursor: pointer;
    opacity:0.25;
    display: flex;
    padding: 0 2px;
    height: 32px;
}
.drawControlsEraseBtn{
    // margin: 6px 8px 10px 8px;
    cursor: pointer;
    opacity:0.25;
    transition:opacity 0.25s ease-in-out;
    padding: 0 2px;
    height: 32px;
}

.controlsPill .drawControlsEraseBtn svg:first-of-type, .controlsPill .drawControlsBrushBtn svg:first-of-type {
    border-right: none;
}

.drawControlsBrushBtn:global(.selected){
    opacity:1;   
}

.drawControlsEraseBtn:global(.selected){
    opacity:1;
}

.drawControlsColorRow[tool="marker"] .drawControlsColor:global(.selected){
    border: 1px solid black;
    border-radius: 24px;
}

:global(.presentationMode) .menuRightColContainer{
    // transition : background-color 1s ease-in-out;
    height: auto;
    max-height: auto;
    overflow: hidden scroll;
    width: 100%;
    margin-right: 0;
    padding-bottom: 40px;
}

:global(.controllerApp) .menuRightColContainer{
    top: 0;
}

:global(.presentationMode) .menuColRight{
    position: absolute;
    right:0;
    top:32%;
    border-radius: 75px 0 0 0;
    bottom:0;
    height: auto;
   
    // transition: border-radius 1s ease-in-out;
}

:global(.controllerApp) .menuColRight {
    z-index: 2;
}

:global(.controllerApp) .onLanding .menuColRight {
    z-index: 3;
}


:global(.controllerApp) .leftNav .menuColRight {
    background: none;

}


:global(.controllerApp) .menuColOn .menuColRight {
    z-index: 3;
}

// :global(.presentationMode)  .menuColRight{
//     padding-left: 82px;
// }

:global(.presentationMode) .sideDragger{
    top:32%;
}
:global(.presentationMode) .menuModuleContainer {
    max-height: 100%;
    overflow-y: scroll;
}

.separator {
    height:0;
    border-right: 1px solid #e6e6e6;
    width: 2px;
    height: scaleValue(38px,42px);
    z-index: 1;
}


.onLanding .menuBurger, .onLanding .leftNavBurger, .onLanding .toolBtn {
    opacity: 0;
    width: 0;
}



.leftNav .menuBurger {
    position: fixed;
    bottom: 2.7%;
 
    // left: var(--sideIndent);
    left: calc(100vw + var(--sideIndent));
    opacity: 1;
    transition: opacity 0.3s ease 1s;
    width: auto;
    height: calc((38px + var(--lerpAspect)* 4px)* var(--lerpScale));
    // bottom: 2.7%;
    // margin-left:var(--sideIndent);
}


:global(.letterBoxed) :global(.mainModuleContainer) .leftNav .menuBurger {
    position: absolute;
    bottom: 2.7%;
    left: calc(100vw + var(--sideIndent) + 0px);
}

:global(.mainModuleContainer) .leftNav .menuBurger {
    left: calc(100vw + var(--sideIndent));
    position: absolute;
    bottom: 2.7%;
}

.leftNav .menuBurger.hide {
    opacity: 0;
    transition: opacity 0s ease 0s;
}

@media screen and (min-width: 2400px) {

    .leftNav.menuContainer {
        // width: 15vw;
    }
    .buttonContainer {
        // width: 12vw;
    }
    .onLanding .menuGrid .menuTitle {
        margin: 20vh 0 40px 0;
    }
}


@media screen and (max-width: 1366px) {
    .leftNav.menuContainer {
        width:360px;
        height: 100%;
    }


    .menuLink {
        width: 250px;
        left: 50px;
    }

    .leftNav .menuInnerColumn .logo {
        max-width: 240px;
    }
    
    .leftNav .menuModuleContainer {
        grid-template-columns: repeat(2, 90px);
        padding: 0 40px;
        width: calc(100% - 40px);
        gap: 54px;
        row-gap: 27px;
        padding-top: 12px;
        max-height: calc(100vh - 200px);
    }

    .buttonContainerBkg {
        height: 120px;
        width: 360px;
        }

    .buttonContainer{
        position: absolute;
        width: 310px;
        bottom: 2.7%;
        gap: 18px;
        height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));

    }
    
}


@media screen and (max-width: 1200px) {
    .onLanding .menuInnerColumn {
        width: 96%;
        height: 80vh;
        margin-left: 2%;
        padding-top: 20vh;
    }

    .onLanding .menuGrid .menuModuleContainer{
        display: grid;
        grid-template-columns: repeat(6, 120px);
        grid-template-rows: repeat(2, 1fr);
        gap: 48px;
    }
}