.container{
    z-index: 1;
    visibility: hidden;
}

.moduleHolder{
    /* visibility: hidden; */
    /* background-color:var(--primary-background); */
  
}

.backgroundShadow{
    background-color: black;
    opacity: 0.65;
    visibility: hidden;
    
}

.moduleHolder h1{
    color:var(--primary);
    text-align: center;
}

.closeBtnContainer{
    background-color:transparent;
    position: absolute;
    left:var(--sideIndent);
    bottom: var(--sideIndent);
    display: flex;
    gap: 18px;
    flex-direction: row-reverse;
    /* --svg-stroke:var(--secondary);
    --svg-stroke-hover:var(--primary-accent); */
}

.btn{
    cursor: pointer;
    background-color: var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
}

.btn  :global(path){
    fill:var(--color) !important;
    transition: fill 0.25 ease-in-out
  }
 .btn:hover :global(path){
    fill:var(--hover) !important;
 }
  

.btn:hover{
    background-color: silver;
}

.closeBtnContainer .btn:nth-child(1){
    border-radius: 15px 0 0 0;
}


.imgOverlay{
    width: 85%;
    height: 85%;
    object-fit: contain;
}


